import React from 'react';
import Seo from '../../components/Seo';
import MarginContainer from '../../components/MarginContainer';
import PageContentWrapper from '../../components/pageElements/PageContentWrapper';
import useCommonTitles from '../../components/useCommonTitles';
import Header from './header';
import Body from './body';
import QandA from './qanda';

function ProductsPage() {

  return (
    <>
      <Seo title="NesisAI" />
      <PageContentWrapper>
        <MarginContainer>
          <Header/>
          <Body/>
          <QandA/>
        </MarginContainer>
      </PageContentWrapper>
    </>
  );
}

export default ProductsPage;
